@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@200&family=Poppins:wght@500&family=Quicksand:wght@600&family=Rancho&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap);
.App {
  text-align: center;
}

body {
    margin: 0px !important;
}

.welcome-text {
    font-family: 'Quicksand', sans-serif;
    font-weight: 700;
    line-height: 1.5;
    font-size: 2.125rem;
    color: #F6F6F2;
    margin-bottom: 0.5rem;
}

.main-container {
    background-image: url(/static/media/shape-new.4ab0e5a2.png);
    background-repeat: no-repeat;
    background-size: cover;
    padding: 0px !important;
    margin: 0px !important;
    background-color: #294152;
    font-family: 'Quicksand', sans-serif;
}

.svg-bg {
    background-image: url(/static/media/mask-c-dark.4737e7a4.svg);
    background-repeat: no-repeat;
    background-position: bottom;
}

.cover-bg {
    background-image: url(/static/media/shape-new.4ab0e5a2.png);
    background-repeat: no-repeat;
    background-size: cover;
}

.grid-content {
    height: 89vh;
    justify-content: space-around;
}

.inner-text {
    color: #BADFE7;
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.7;
}

.connect-btn:hover {
    background-color: #388087 !important;
}

.main-img {
    width: 380px;
}

.nav-ul {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 0px !important;
}

.nav-ul li {
    list-style: none;
}

.header-li-link {
    text-decoration: none;
    cursor: pointer;
    font-weight: 500;
    font-size: 16px !important;
    color: white;
    text-transform: uppercase;
    font-family: 'Roboto', sans-serif;
    transition: 0.2s all linear;
}

.header-li-link:hover {
    color: #d13f55;
}

.header-bar {
    padding-top: 30px !important;
}

@media screen and (max-width:965px) {
    .grid-content,
    .svg-bg,
    .cover-bg {
        min-height: 100vh;
    }
    .main-img {
        width: 350px;
    }
    .text-grid {
        text-align: center;
        margin-bottom: 0px !important;
    }
}

@media screen and (max-width:670px) {
    .logo-img {
        text-align: center;
    }
}

@media screen and (max-width:500px) {
    .main-img {
        width: 300px;
    }
    .connect-btn {
        padding: 25px 25px !important;
    }
    .welcome-text {
        font-size: 1.70rem;
        line-height: 1.5;
    }
    .inner-text {
        font-size: 1.1rem;
    }
}
.footer-container {
    background-color: #294152 !important;
}

.stay-text {
    text-align: center;
    font-family: 'Quicksand', sans-serif;
    padding-top: 100px;
    padding-bottom: 50px;
    color: #f6f6f2;
    font-weight: 700;
    font-size: 1.375rem;
}

.join-text {
    text-align: center;
    color: #BADFE7 !important;
    font-size: 18px;
    margin-bottom: 1rem;
    color: var(--dark-green);
    font-family: 'Quicksand', sans-serif;
}

.footer-links ul {
    display: flex;
    flex-direction: row;
    list-style: none;
    justify-content: center;
    margin-right: 40px;
    margin-top: 100px;
    align-items: center;
}

.footer-links ul li {
    margin: 10px;
    border-radius: 50%;
    background-color: #495D6C;
    width: 35px;
    height: 35px;
    transition: 0.2s all linear;
}

.footer-links ul li a {
    text-decoration: none;
    padding: 9px;
    font-size: 17px;
    color: white;
}

.footer-li-link svg {
    padding-top: 9px;
}

.footer-links ul li:hover {
    background-color: #F42F54;
}

.copyright-text {
    text-align: center;
    margin-top: 30px;
    margin-bottom: 100px;
    font-family: 'Quicksand', sans-serif;
}

.copyright,
.trademark {
    padding-bottom: 5px;
    color: white;
    font-size: 15px;
}

.build-in {
    font-size: 15px;
    font-weight: 600;
    color: white;
    font-family: 'Quicksand', sans-serif;
}
body {
  background: #000000;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

