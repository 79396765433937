@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200&family=Poppins:wght@500&family=Quicksand:wght@600&family=Rancho&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap');
body {
    margin: 0px !important;
}

.welcome-text {
    font-family: 'Quicksand', sans-serif;
    font-weight: 700;
    line-height: 1.5;
    font-size: 2.125rem;
    color: #F6F6F2;
    margin-bottom: 0.5rem;
}

.main-container {
    background-image: url('./assets//images/shape-new.png');
    background-repeat: no-repeat;
    background-size: cover;
    padding: 0px !important;
    margin: 0px !important;
    background-color: #294152;
    font-family: 'Quicksand', sans-serif;
}

.svg-bg {
    background-image: url('./assets/images/mask-c-dark.svg');
    background-repeat: no-repeat;
    background-position: bottom;
}

.cover-bg {
    background-image: url('./assets//images/shape-new.png');
    background-repeat: no-repeat;
    background-size: cover;
}

.grid-content {
    height: 89vh;
    justify-content: space-around;
}

.inner-text {
    color: #BADFE7;
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.7;
}

.connect-btn:hover {
    background-color: #388087 !important;
}

.main-img {
    width: 380px;
}

.nav-ul {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 0px !important;
}

.nav-ul li {
    list-style: none;
}

.header-li-link {
    text-decoration: none;
    cursor: pointer;
    font-weight: 500;
    font-size: 16px !important;
    color: white;
    text-transform: uppercase;
    font-family: 'Roboto', sans-serif;
    transition: 0.2s all linear;
}

.header-li-link:hover {
    color: #d13f55;
}

.header-bar {
    padding-top: 30px !important;
}

@media screen and (max-width:965px) {
    .grid-content,
    .svg-bg,
    .cover-bg {
        min-height: 100vh;
    }
    .main-img {
        width: 350px;
    }
    .text-grid {
        text-align: center;
        margin-bottom: 0px !important;
    }
}

@media screen and (max-width:670px) {
    .logo-img {
        text-align: center;
    }
}

@media screen and (max-width:500px) {
    .main-img {
        width: 300px;
    }
    .connect-btn {
        padding: 25px 25px !important;
    }
    .welcome-text {
        font-size: 1.70rem;
        line-height: 1.5;
    }
    .inner-text {
        font-size: 1.1rem;
    }
}